import React, { useContext, useEffect } from "react";
import UserContext from "../components/UserContext";
import Home_1 from "./home/home_1";
import Meta from "../components/Meta";
import { createThirdwebClient } from "thirdweb";
// import { createAuth } from "thirdweb/auth";
import { useActiveAccount } from "thirdweb/react";
import { getAllNews } from "../lib/news";
export default function Home({newses}) {
  const { scrollRef } = useContext(UserContext);
  const  user  = useActiveAccount();

  // const thirdwebAuth = createAuth({
  //   domain: "localhost:3000",
  //   client,
  // });

  useEffect(() => {
    window.scrollTo(0, scrollRef.current.scrollPos);
    const handleScrollPos = () => {
      scrollRef.current.scrollPos = window.scrollY;
    };
    window.addEventListener("scroll", handleScrollPos);
    return () => {
      window.removeEventListener("scroll", handleScrollPos);
    };
  }, [user]);

  return (
    <>
      <Meta />
      <Home_1  data={newses}/>
    </>
  );
}

export async function getServerSideProps() {

  const { newses } = await getAllNews();

  return {
    props: {
      newses: newses || [],
    },
    // revalidate: 60,
  };


}
